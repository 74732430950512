
import { VueRecaptcha } from 'vue-recaptcha';
import { RECAPTCHAV2_SITE_KEY } from "@/api-keys";
import emailjs from '@emailjs/browser';

export default {
  components: { VueRecaptcha },

  data() {
    return {
      recaptchaKey: RECAPTCHAV2_SITE_KEY,
      processing: false,
      result: null,
      errorMessage: null,
      senderName: "",
      senderEmail: "",
      message: ""
    }
  },
  methods: {
    submitForm() {
      this.processing = true;
      this.result = null;
      this.errorMessage = null;
      this.$refs.invisibleRecaptcha.execute();
    },

    onVerify(response) {
      const templateParams = {
        "sender_name": this.senderName,
        "sender_email": this.senderEmail,
        "message": this.message,
        "g-recaptcha-response": response,
      }

      this.$refs.invisibleRecaptcha.reset();

      emailjs.send('ip_email_service', 'ip_email_template', templateParams, '4oHUKb7kSil6C8X0a')
        .then((result) => {
            this.processing = false;
            this.result = "success";
            console.log('SUCCESS!', result.text);
          }, (error) => {
            this.processing = false;
            this.result = "error";
            this.errorMessage = error.text;
          });
    },

    onExpired() {
      this.$refs.invisibleRecaptcha.reset();
    },
  }
}
